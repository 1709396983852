import React from "react"
import PropTypes from "prop-types"
import { Form, Button, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'lodash';

class SelectClassification extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      classifications: props.classifications,
      classification: props.classification,
      values: props.values,
      errors: props.errors,
    };
  }

  componentDidMount() {
  }

  changeValues(field, index, event) {
    const updatedArray = this.state.values;
    updatedArray[index] = { ...updatedArray[index], [field]: event.target.value };
    this.setState({ values: updatedArray });
    document.getElementById('values').value = JSON.stringify(updatedArray);
  };

  addValues(index) {
    const updatedArray = this.state.values;
    updatedArray.push({ value: "", description: "" });
    this.setState({ values: updatedArray });
    document.getElementById('values').value = JSON.stringify(updatedArray);
  };

  removeValues(index) {
    const updatedArray = this.state.values;
    updatedArray.splice(index, 1);
    this.setState({ values: updatedArray });
    document.getElementById('values').value = JSON.stringify(updatedArray);
  };

  renderValues() {
    const { classification, values, errors } = this.state;

    if (classification && (classification==="select")) {
      const result = values.map((item, index) => {
        const value = item["value"];
        const description = item["description"];
        return (
          <Form.Group key={index} controlId={`formValues${index}`} className="form-group">
            <Row className="mb-2">
              <Col xs="10">
                <Form.Control type="text" placeholder="Value" value={value} onChange={this.changeValues.bind(this, "value", index)} />
              </Col>
              <Col xs="2">
                <Button variant="light" size="sm" className="btn-block" onClick={this.removeValues.bind(this, index)}><i className="fa fa-times"></i></Button>
              </Col>
            </Row>
            <Row>
              <Col xs="10">
                <Form.Control as="textarea" rows="2" placeholder="Description" value={description} onChange={this.changeValues.bind(this, "description", index)} />
              </Col>
            </Row>
          </Form.Group>
        );
      });

      return (
        <Form.Group controlId="formValues" className="mt-3 form-group">
          <Form.Label className={errors["values"] && "text-danger"}>
            * Values {errors["values"] && errors["values"].join()}
          </Form.Label>
          {result}
          <Row className="align-items-center">
            <Col xs="7">
            </Col>
            <Col xs="5">
              <Button variant="primary" size="sm" className="btn-block" onClick={this.addValues.bind(this)}>
                Add a value
              </Button>
            </Col>
          </Row>
        </Form.Group>
      )
    }
    return null;
  }

  renderSelectClassification() {
    const classifications = this.state.classifications;
    const classification = this.state.classification;
    const values = this.state.values;

    const handleInputChange = value => {
    };

    const handleChange = value => {
      this.setState({
        classification: value.name,
      });
      if (value) {
        document.getElementById('classification').value = value.name;
      } else {
        document.getElementById('classification').value = null;
      }
    };

    return (
      <>
        <Select
          options={classifications}
          name={'classification'}
          defaultValue={classification ? _.find(classifications, {"name": classification}) : null}
          getOptionLabel={a => a.description}
          getOptionValue={a => a.name}
          placeholder="Select a type..."
          isClearable
          isSearchable
          onInputChange={handleInputChange}
          onChange={handleChange}
          styles={{
            valueContainer: (base) => ({ ...base, minHeight: '40px'}),
          }}
        />
        {this.renderValues()}
      </>
    );
  }

  render () {
    return (
      <>
        {this.renderSelectClassification()}
      </>
    );
  }
}

SelectClassification.propTypes = {
  classifications: PropTypes.array,
  classification: PropTypes.string,
  values: PropTypes.array,
  errors: PropTypes.object,
};

export default SelectClassification;
