import React from "react"
import PropTypes from "prop-types"
import { Form, Button, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'lodash';

class SelectConditions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form_attributes: props.form_attributes,
      conditions: props.conditions,
      errors: props.errors,
    };
  }

  componentDidMount() {
  }

  handleChangeConditions(field, index, event) {
    this.changeConditions(field, index, event.target.value);
  };

  changeConditions(field, index, value) {
    const updatedArray = this.state.conditions;
    updatedArray[index] = { ...updatedArray[index], [field]: value };
    this.setState({conditions: updatedArray});
    document.getElementById('conditions').value = JSON.stringify(updatedArray);
  };

  addConditions(index) {
    const updatedArray = this.state.conditions;
    updatedArray.push({ id: "", operator: "", value: "" });
    this.setState({conditions: updatedArray});
    document.getElementById('conditions').value = JSON.stringify(updatedArray);
  };

  removeConditions(index) {
    const updatedArray = this.state.conditions;
    updatedArray.splice(index, 1);
    this.setState({conditions: updatedArray});
    document.getElementById('conditions').value = JSON.stringify(updatedArray);
  };

  renderSelectFormAttribute(form_attribute_id, index) {
    const form_attributes = this.state.form_attributes;
    const form_attribute = form_attribute_id ? _.find(form_attributes, {"id": form_attribute_id}) : null;

    const handleInputChange = value => {
    };

    const handleChange = value => {
      const val = value ? value["id"] : "";
      this.changeConditions("id", index, val);
    };

    return (
      <Select
        options={form_attributes}
        name={`form_attribute_${index}`}
        placeholder="Select an attribute..."
        defaultValue={form_attribute}
        getOptionLabel={a => a.name}
        getOptionValue={a => a.id}
        isClearable
        isSearchable
        onInputChange={handleInputChange}
        onChange={handleChange}
        styles={{
          valueContainer: (base) => ({ ...base, minHeight: '40px'}),
        }}
      />
    );
  }

  renderSelectOperator(operator, index) {
    const operators = [
      { value: "==", label: "Equal =" },
      { value: "!=", label: "Not equal !=" },
      { value: ">", label: "Greater >" },
      { value: ">=", label: "Greater or equal >=" },
      { value: "<", label: "Less <" },
      { value: "<=", label: "Less or equal <=" },
    ];
    const default_value = _.find(operators, {"value": operator});

    const handleInputChange = value => {
    };

    const handleChange = value => {
      const val = value ? value["value"] : "";
      this.changeConditions("operator", index, val);
    };

    return (
      <Select
        options={operators}
        name={`operator_${index}`}
        placeholder="Select an operator..."
        defaultValue={default_value}
        isClearable
        isSearchable
        onInputChange={handleInputChange}
        onChange={handleChange}
        styles={{
          valueContainer: (base) => ({ ...base, minHeight: '40px'}),
        }}
      />
    );
  }

  renderSelectConditions() {
    const form_attributes = this.state.form_attributes;
    const conditions = this.state.conditions;
    const errors = this.state.errors;

    const result = conditions.map((condition, index) => {
      const id = condition["id"];
      const operator = condition["operator"];
      const value = condition["value"];
      return (
        <Form.Group key={index} controlId={`formConditions${index}`} className="form-group">
          <Row className="mb-4">
            <Col xs="10">
              {this.renderSelectFormAttribute(id, index)}
              <Row className="mb-2 mt-2">
                <Col xs="6">
                  {this.renderSelectOperator(operator, index)}
                </Col>
                <Col xs="6">
                  <Form.Control type="text" placeholder="Value" value={value} onChange={this.handleChangeConditions.bind(this, "value", index)} />
                </Col>
              </Row>
            </Col>
            <Col xs="2">
              <Button variant="light" size="sm" className="btn-block" onClick={this.removeConditions.bind(this, index)}><i className="fa fa-times"></i></Button>
            </Col>
          </Row>
        </Form.Group>
      );
    });
    return (
      <div>
        {result}
        <Row className="align-items-center">
          <Col xs="7">
          </Col>
          <Col xs="5">
            <Button variant="primary" size="sm" className="btn-block" onClick={this.addConditions.bind(this)}>
              Add a condition
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  render () {
    return (
      <div>
        {this.renderSelectConditions()}
      </div>
    );
  }
}

SelectConditions.propTypes = {
  form_attributes: PropTypes.array,
  conditions: PropTypes.array,
};

export default SelectConditions;
