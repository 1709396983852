import React from "react"
import PropTypes from "prop-types"
import { Form, Button, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'lodash';

class ModelAttributes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      attributes_array: props.attributes_array
    };
  }

  componentDidMount() {
  }

  handleChangeModelAttributes(field, index, event) {
    this.changeModelAttributes(field, index, event.target.value);
  };

  changeModelAttributes(field, index, value) {
    const updatedArray = this.state.attributes_array;
    updatedArray[index] = { ...updatedArray[index], [field]: value };
    this.setState({attributes_array: updatedArray});
    document.getElementById('attributes_array').value = JSON.stringify(updatedArray);
  };

  addModelAttributes(index) {
    const updatedArray = this.state.attributes_array;
    updatedArray.push({ id: `${Math.floor(Math.random()*16777215).toString(16)}`, label: "", classification: "", values: [] });
    this.setState({attributes_array: updatedArray});
    document.getElementById('attributes_array').value = JSON.stringify(updatedArray);
  };

  removeModelAttributes(index) {
    const updatedArray = this.state.attributes_array;
    updatedArray.splice(index, 1);
    this.setState({attributes_array: updatedArray});
    document.getElementById('attributes_array').value = JSON.stringify(updatedArray);
  };

  renderModelAttributes() {
    const attributes_array = this.state.attributes_array;

    const result = attributes_array.map((attribute, index) => {
      const label = attribute["label"];
      const classification = attribute["classification"];
      const values = attribute["values"];
      return (
        <Form.Group key={index} controlId={`formModelAttributes${index}`} className="form-group">
          <Row className="mb-4">
            <Col xs="10">
              {JSON.stringify(attribute)}
            </Col>
            <Col xs="2">
              <Button variant="light" size="sm" className="btn-block" onClick={this.removeModelAttributes.bind(this, index)}><i className="fa fa-times"></i></Button>
            </Col>
          </Row>
        </Form.Group>
      );
    });
    return (
      <div>
        {result}
        <Row className="align-items-center">
          <Col xs="7">
          </Col>
          <Col xs="5">
            <Button variant="primary" size="sm" block onClick={this.addModelAttributes.bind(this)}>
              Add an attribute
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  render () {
    return (
      <div>
        {this.renderModelAttributes()}
      </div>
    );
  }
}

ModelAttributes.propTypes = {
  attributes_array: PropTypes.array,
};

export default ModelAttributes;
